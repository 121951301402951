@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'suneditor/dist/css/suneditor.min.css';
@import "fonts";

#root {
  min-height: 100vh;
}
.sun-editor {
  max-width: 1000px!important;
  margin: 0 auto!important;
}

.sun-editor{
  border-radius: 15px 15px 0 0!important;
}

.se-wrapper {
  padding: 15px!important;
}
.se-btn-tray {
  display: flex!important;
  justify-content: center!important;
  flex-wrap: wrap;
}

.seWrp {
  max-width: 908px!important;
  margin: 0 auto!important;
  border: 1px solid silver!important;
  border-radius: 15px!important;
}

.se-image-container img {
  border-radius: 15px;
  overflow: hidden;
}

.se-video-container {
  max-width: 676px!important;
  outline: none!important;
  border-radius: 15px!important;
  overflow: hidden!important;
  height: auto!important;
  object-fit: cover!important;
}

.se-image-container figure figcaption {
  background: none!important;
}

.se-toolbar {
  border-radius: 15px 15px 0 0!important;
  padding: 7px!important;
}

.se-image-container figure img {
  object-fit: cover;
}

.se-image-container figure figcaption div {
  font-family: 'Suisse Intl';
  font-weight: 400;
  font-size: clamp(12px, 2vw, 14px);
  color: #717171;
}

.articleHeading {
  font-family: CoFoKak !important;
  line-height: normal;
  color: #000!important;

  &_h3 {
    font-size: clamp(36px, 6vw, 64px)!important;
    margin: clamp(20px, 3vw, 30px) 0!important;
  }

  &_h4 {
    font-size: clamp(24px, 5vw, 46px)!important;
    margin: clamp(15px, 2vw, 20px) 0!important;
  }
}

.articleText {
  font-family: 'Suisse Intl';
  font-weight: 400;
  color: #000;
  font-size: clamp(18px, 2vw, 22px)!important;
}

.articleBlockquote {
  line-height: normal;
  font-style: italic!important;
  font-size: clamp(18px, 2vw, 22px)!important;
  margin: 30px 0 !important;
  padding: 0 0 0 clamp(12px, 2vw, 16px)!important;
  border-width: 0 0 0 3px!important;
  border-color: black!important;
  color: black!important;
}

.articleBlockquote p {
  font-family: 'Source Serif Pro';
}

.articleBgBlock {
  padding: 20px!important;
  border-radius: 15px;
  background-color: #d9d9d9;
  margin: 15px 0!important;
}