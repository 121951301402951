@font-face {
  font-family: 'CoFoKak';
  src: url('./fonts/CofoKakMedium/CoFoKak-Medium.woff2') format('woff2'),
  url('./fonts/CofoKakMedium/CoFoKak-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif Pro';
  src: local('Source Serif Pro Italic'), local('SourceSerifPro-It'), url('./fonts/SourceSerifPro/SourceSerifPro-It.woff2') format('woff2'), url('./fonts/SourceSerifPro/SourceSerifPro-It.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-BookItalic.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Thin.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Book.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Black.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Bold.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-UltraLight.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-BoldItalic.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-LightItalic.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Medium.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-UltraLightItalic.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-SemiBold.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-MediumItalic.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-ThinItalic.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Italic.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Light.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-Regular.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/SuisseIntl/SuisseIntl-BlackItalic.woff2') format('woff2'),
  url('./fonts/SuisseIntl/SuisseIntl-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

